.app {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  background-color: #f8f9fd;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.bavovna-form {
  background-color: white;
  padding: 2rem;
}
@media only screen and (min-width: 900px) {
  .bavovna-form {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.st {
  margin-top: 15px !important;
}

.input-field {
  height: auto !important;
}

.bavovna-logo {
  width: 80px;
}
@media only screen and (max-width: 600px) {
  .bavovna-logo {
    width: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .navbar-brand {
    font-size: 17px;
  }
}

.button-container.btn.btn-primary {
  background-color: black;
  border-color: black;
}
.button-container.btn.btn-primary:hover {
  background-color: #8e1010;
}

canvas {
  margin-left: 16%;
  width: initial !important;
}
@media only screen and (max-width: 1200px) {
  canvas {
    margin-left: 8%;
  }
}
@media only screen and (max-width: 1150px) {
  canvas {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 800px) {
  canvas {
    margin-left: -5%;
  }
}

@media only screen and (max-width: 500px) {
  canvas {
    margin-left: 0%;
  }
}

#first {
  color: red;
}

#second {
  color: green;
}

#third {
  color: rgb(212, 166, 0);
}

#fourth {
  color: rgb(0, 60, 212);
}

hr {
  margin: 0.5rem 0;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 17px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.input-container {
  width: 100%;
}

.button-container {
  margin: 10px;
  width: 100%;
}

.navbar-collapse {
  flex-grow: initial;
}

.hint {
  margin-bottom: 0;
  font-size: 0.7rem;
  color: #8a8a8a;
}

#variant-selector {
  margin-bottom: 15px;
}

#board-selector {
  margin-bottom: 15px;
}

.alert-success {
  background-color: #00ce3d;
  color: #fff;
  font-size: 2rem;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* @media only screen and (min-width: 800px) {
  body {
    background-image: url("../public/bavovna_repeat.png");
    background-repeat: initial;
    background-size: 300px;
  }
} */

.board-picture {
  position: absolute;
  max-width: 240px;
  max-height: 160px;
  margin-top: -40px;
}

@media only screen and (min-width: 200px) and (max-width: 1200px) {
  .board-picture {
    margin-top: initial;
    max-height: 150px;
    max-width: 180px;
    position: initial;
  }
}

.password-icon {
  cursor: pointer;
}

.servo-num {
  margin-top: 2rem;
  font-size: 1.5rem;
}

.led-picture {
  max-width: 50px;
  max-height: 50px;
  margin-top: -9px;
  /* margin-left: 8px; */
}

.led-container {
  height: 50px;
}

.hidden {
  visibility: initial;
}
@media only screen and (min-width: 950px) {
  .hidden {
    visibility: hidden;
  }
}

.ch2 {
  margin-bottom: 0;
}

.version-number {
  display: block;
  position: absolute;
  margin-left: 51px;
  margin-top: -20px;
  font-size: 0.5rem;
}
@media only screen and (min-width: 600px) {
  .version-number {
    margin-left: 82px;
    margin-top: -28px;
    font-size: 0.75rem;
  }
}

.info-icon {
  display: inline !important;
  cursor: help;
  margin-left: 10px;
}

@media (max-width: 576px) {
  .hide-on-small {
    display: none !important;
  }
}

@media (min-width: 577px) {
  .hide-on-large {
    display: none !important;
  }
}

.hidden-arrows {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.shim-selector {
  max-width: 100%;
}

/* Snowflakes START */

.snowflakes {
  animation: fadeOut ease 80s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

.snowflake {
color: #fff;
font-size: 2em;
font-family: Arial, sans-serif;
text-shadow: 0 0 2px #002B31;
pointer-events: none;
}

@media (max-width: 1200px) {
  .snowflake {
      font-size: 1em;
  }
}

@-webkit-keyframes snowflakes-fall {
0% {
  top: -10%
}

100% {
  top: 100%
}
}

@-webkit-keyframes snowflakes-shake {

0%,
100% {
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

50% {
  -webkit-transform: translateX(80px);
  transform: translateX(80px)
}
}

@keyframes snowflakes-fall {
0% {
  top: -10%
}

100% {
  top: 100%
}
}

@keyframes snowflakes-shake {

0%,
100% {
  transform: translateX(0) rotate(0deg)
}

50% {
  transform: translateX(80px) rotate(359deg)
}
}


.snowflake {
position: absolute;
top: -10%;
z-index: 999999;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
cursor: default;
-webkit-animation-name: snowflakes-fall, snowflakes-shake;
-webkit-animation-duration: 10s, 3s;
-webkit-animation-timing-function: linear, ease-in-out;
-webkit-animation-iteration-count: infinite, infinite;
-webkit-animation-play-state: running, running;
animation-name: snowflakes-fall, snowflakes-shake;
animation-duration: 11s, 9s;
animation-timing-function: linear, ease-in-out;
animation-iteration-count: infinite, infinite;
animation-play-state: running, running
}

.snowflake:nth-of-type(0) {
left: 1%;
-webkit-animation-delay: 0s, 0s;
animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
left: 10%;
-webkit-animation-delay: 1s, 1s;
animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
left: 20%;
-webkit-animation-delay: 6s, .5s;
animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
left: 30%;
-webkit-animation-delay: 4s, 2s;
animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
left: 40%;
-webkit-animation-delay: 2s, 2s;
animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
left: 50%;
-webkit-animation-delay: 8s, 3s;
animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
left: 60%;
-webkit-animation-delay: 6s, 2s;
animation-delay: 6s, 2s
}
@media (max-width: 600px) {
  .snowflake:nth-of-type(6) {
      display: none;
  }
}

.snowflake:nth-of-type(7) {
left: 70%;
-webkit-animation-delay: 2.5s, 1s;
animation-delay: 2.5s, 1s
}
@media (max-width: 600px) {
  .snowflake:nth-of-type(7) {
      display: none;
  }
}

.snowflake:nth-of-type(8) {
left: 80%;
-webkit-animation-delay: 1s, 0s;
animation-delay: 1s, 0s
}
@media (max-width: 600px) {
  .snowflake:nth-of-type(8) {
      display: none;
  }
}

.snowflake:nth-of-type(9) {
left: 90%;
-webkit-animation-delay: 3s, 1.5s;
animation-delay: 3s, 1.5s
}
@media (max-width: 600px) {
  .snowflake:nth-of-type(9) {
      display: none;
  }
}

.snowflake:nth-of-type(10) {
left: 25%;
-webkit-animation-delay: 2s, 0s;
animation-delay: 2s, 0s
}
@media (max-width: 600px) {
  .snowflake:nth-of-type(10) {
      display: none;
  }
}

.snowflake:nth-of-type(11) {
left: 65%;
-webkit-animation-delay: 4s, 2.5s;
animation-delay: 4s, 2.5s
}
@media (max-width: 600px) {
  .snowflake:nth-of-type(11) {
      display: none;
  }
}

/* Snowflakes END */

.release-notif.container {
  /* position: absolute; */
  background-color: #faff5e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  min-width: fit-content;
  max-width: max-content;
  margin-top: 10px;
  margin-bottom: 10px;
}

.notif-header-span {
  display: flex;
  padding-right: 30px;
}

.notif-close-button {
  /* right: 0;
  position: absolute;
  margin-left: 10px;
  margin-right: 10px; */
  display: block;
  position: revert-layer;
  float: right;
  background-color: #3bbd00;
  color: #fff;
  font-weight: bold;
  border-style: none;
  /* margin-top: -40px; */
}


.notif-close-button:hover {
  /* background-color: #aaaf5e; */
  background-color: #236f00;
  color: #fff;
}

.show-release-log, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.show-release-log:hover {
  /* background-color: #faff5e; */
  font-weight: bold;
}